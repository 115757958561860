import React from "react";
import PropTypes from "prop-types";
import FormBlockContainer from "../../containers/common/forms/FormBlockContainer";
import { PAGE_OPTIONS, PAGES } from "../../helpers/constants";

const FormWithPicture = (props) => {
  const {
    content: { jpeg, jpeg2x, webp, webp2x, title },
    customClass,
    pageFormState,
    updatePageFormState,
    isLoading,
    formRef,
  } = props;

  return (
    <section
      ref={formRef}
      className={`PgASB-FormWithPicture ${customClass} ${
        pageFormState ? "PgASB-FormWithPicture-secondState" : ""
      }`}
    >
      <div className="PgASB-FormWithPicture__img">
        <picture>
          <source type="image/webp" srcSet={`${webp} 1x, ${webp2x} 2x`} />
          <img srcSet={`${jpeg} 1x, ${jpeg2x} 2x`} alt="" />
        </picture>
      </div>
      <FormBlockContainer
        pageFormState={pageFormState}
        updatePageFormState={updatePageFormState}
        page={PAGES.AMAZON_SELLERS_BUNDLE}
        title={title}
        buttonTextFirstState="Try AMZScout for free!"
        buttonTextSecondState="Try AMZScout for free!"
        redirectUrl={PAGE_OPTIONS[PAGES.AMAZON_SELLERS_BUNDLE].REDIRECTED_URL}
        isLoading={isLoading}
      />
    </section>
  );
};

FormWithPicture.propTypes = {
  content: PropTypes.object.isRequired,
  pageFormState: PropTypes.bool.isRequired,
  updatePageFormState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  customClass: PropTypes.string,
  formRef: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FormWithPicture;
