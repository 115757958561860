import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import track from "../../tracking/analytics";
import { trackGa } from "../../tracking/googleAnalytics";
import { MediaContext } from "../../store/context/media-context";
import { LayoutContext } from "../../store/context/layout-context";

const DealsBannerContainer = (props) => {
  const {
    alwaysShowDeals,
    category,
    children,
    closureOnlyCross,
    dealsSection,
    dealsUrl,
    dealsVersion,
    disableRedirect,
    isShowStaticBanner,
    label,
    multipleBannerOptions,
    setIsShowEmptySpace,
    transferSuccess,
  } = props;
  const [isShowDeals, setIsShowDeals] = useState(false);
  const media = useContext(MediaContext);
  const layout = useContext(LayoutContext);
  let timerId = null;

  useEffect(() => {
    if (checkStatus() && dealsSection === "true") {
      media.isMobile ? showDealsBannerMobile() : showDealsBannerDesktop();
      document.addEventListener("scroll", showDealsBanner);
    }
    return () => {
      document.removeEventListener("scroll", showDealsBanner);
    };
  }, [media.isMobile]);

  useEffect(() => {
    layout.setPanelStatus(getStateShow());
    !!setIsShowEmptySpace && setIsShowEmptySpace(isShowDeals);
  }, [isShowDeals, isShowStaticBanner]);

  const checkStatus = () =>
    !sessionStorage.getItem(dealsVersion) || alwaysShowDeals;

  const handleSuccess = () => {
    setIsShowDeals(false);
    !!setIsShowEmptySpace && setIsShowEmptySpace(false);
    transferSuccess && transferSuccess();
  };

  const showDealsBanner = () => {
    media.isMobile && showDealsBannerMobile();
  };

  const isHeightForDeals = () => window.scrollY > 290;

  const showDealsBannerMobile = () => {
    if (checkStatus()) {
      setIsShowDeals(isHeightForDeals());
    } else {
      isShowDeals && setIsShowDeals(false);
      document.removeEventListener("scroll", showDealsBannerMobile);
    }
  };
  const sendEvents = (status) => {
    const action = status ? "open" : "close";
    const labelEvents = label ?? "Get Holiday Deals!";
    const categoryEvents = category ?? "Index";
    track(categoryEvents, action, labelEvents);
    trackGa(action, { categoryEvents, labelEvents });
  };
  const showDealsBannerDesktop = () => {
    if (checkStatus()) {
      if (multipleBannerOptions) {
        setIsShowDeals(!isShowStaticBanner);
      } else {
        if (!window.isEnabledTimer) {
          window.isEnabledTimer = true;
          timerId = setTimeout(function () {
            setIsShowDeals(true);
            window.isEnabledTimer = false;
          }, 1000);
        } else {
          clearTimeout(timerId);
          setIsShowDeals(false);
          window.isEnabledTimer = false;
        }
      }
    }
  };
  const handlerCloseButton = () => {
    sendEvents(alwaysShowDeals);
    if (!alwaysShowDeals) {
      sessionStorage.setItem(dealsVersion, "closed");
      setIsShowDeals(false);
      !!setIsShowEmptySpace && setIsShowEmptySpace(false);
    }
  };
  const clickHandler = (e) => {
    const idForClose = "js-close-panel";
    const isCloseButton =
      e.target.id === idForClose || e.target.parentNode.id === idForClose;
    const isClose = !alwaysShowDeals && !disableRedirect && !closureOnlyCross;
    !closureOnlyCross && sessionStorage.setItem(dealsVersion, "closed");
    if (isCloseButton) {
      handlerCloseButton();
    } else if (!disableRedirect) {
      sendEvents(closureOnlyCross || alwaysShowDeals);
      window.open(dealsUrl, "_blank");
    }
    if (isClose) {
      !!setIsShowEmptySpace && setIsShowEmptySpace(false);
      setIsShowDeals(false);
    }
  };
  const getStateShow = () => {
    if (media.isMobile) {
      return isShowDeals;
    } else if (isShowStaticBanner !== undefined) {
      return !isShowStaticBanner;
    }
    return isShowDeals;
  };
  return (
    <>
      {React.cloneElement(children, {
        isShowDeals: getStateShow(),
        clickOpenHandler: clickHandler,
        onSuccess: handleSuccess,
        isBottomLine: layout.isShowFooter,
      })}
    </>
  );
};

DealsBannerContainer.propTypes = {
  category: PropTypes.string,
  children: PropTypes.object.isRequired,
  closureOnlyCross: PropTypes.bool,
  dealsSection: PropTypes.string.isRequired,
  dealsUrl: PropTypes.string.isRequired,
  dealsVersion: PropTypes.string.isRequired,
  disableRedirect: PropTypes.bool,
  label: PropTypes.string,
  transferSuccess: PropTypes.func,
};

export default DealsBannerContainer;
