import React, { useContext } from "react";
import PropTypes from "prop-types";
import CloseWhiteIcon from "../../../../assets/images/vector/components/common/close_white.svg";
import CloseGreyIcon from "../../../../assets/images/vector/components/common/gray-cross.svg";
import { MediaContext } from "../../../../store/context/media-context";
import "./index.scss";

const Close = ({ hideCloseButton }) => {
  if (hideCloseButton) return null;
  const media = useContext(MediaContext);

  return (
    <button className="PgASB-DealsBundle__close btn_clear PgASB-DealsBundle__closeJS">
      <img
        className="PgASB-DealsBundle__closeJS"
        id="js-close-panel"
        src={media.isMobile ? CloseWhiteIcon : CloseGreyIcon}
        alt="X"
        loading="lazy"
      />
    </button>
  );
};
Close.propTypes = {
  hideCloseButton: PropTypes.bool,
};
export default Close;
